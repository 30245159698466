import React, { useMemo } from 'react';

import { setupContentBuilder } from 'ricos-content/libs/Content';
import { ensureDraftContent } from 'ricos-content/libs/converters';
import { generateKey } from 'wix-rich-content-common';
import type {
  PluginContainerData_Alignment,
  PluginContainerData_Width_Type,
} from 'ricos-schema';

import type { IGroupCoverChangedActivity } from 'api/feed/types';

import { CardContent } from 'wui/CardContent';
import { RichContentViewer } from 'common/components/RichContent/Viewer';

import { getAriaId } from '../a11y';
import { IActivityContentProps } from './types';
import { GROUPS_APP_DEFINITION_ID } from '../../../../constants';

export function CoverImageChanged(props: IActivityContentProps) {
  const { item } = props;

  const feedItemId = item.feedItemId as string;
  const data = item?.activity?.data as IGroupCoverChangedActivity;
  const groupId = item.applicationContext?.contextId as string;

  const content = useMemo(() => {
    const builder = setupContentBuilder(generateKey);

    const content = builder.addImage({
      data: {
        containerData: {
          alignment: 'CENTER' as PluginContainerData_Alignment,
          width: {
            size: 'FULL_WIDTH' as PluginContainerData_Width_Type,
          },
        },
        image: {
          width: 1920,
          height: 1080,
          src: { id: data.src },
        },
      },
      content: { nodes: [] },
    });

    return ensureDraftContent(content);
  }, [data.src]);

  return (
    <CardContent id={getAriaId(feedItemId, 'content')}>
      <RichContentViewer
        usage="FeedItem"
        content={content}
        groupId={groupId}
        postId={item.feedItemId ?? ''}
        containerId={GROUPS_APP_DEFINITION_ID}
      />
    </CardContent>
  );
}

CoverImageChanged.displayName = 'CoverImageChanged';
